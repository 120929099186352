<template>
  <h1>Coupon</h1>
</template>

<script>
export default {
  name: 'Coupon',
  props: {
    msg: String
  }
}
</script>
